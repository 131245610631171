import { chakra } from '@chakra-ui/react';

export const Logo = (props) => (
    <chakra.svg
        {...props} // This spreads the props to allow for styling and other props to be applied
        xmlns="http://www.w3.org/2000/svg"
        //viewBox="0 0 424.3483 100.4749"
        viewBox="0 0 110.4749 100.4749"
        style={{ height: 65, width: 65}}
    >
      <g>
          {/*<g id="type">
            <path d="M166.8175,47.4983c0,9.4508-7.7724,14.4876-23.2551,14.4876-15.0477,0-22.9445-5.6588-22.9445-14.4876h10.4463c.2488,4.5393,5.4717,6.84,13.3685,6.84,8.27,0,11.7519-2.1768,11.7519-5.4721,0-4.4771-6.7151-4.0417-18.5914-6.031s-15.6075-5.5966-15.6075-12.4361c0-7.15,4.85-13.7421,20.83-13.7421,15.3583,0,21.3275,5.8453,21.5763,13.5556h-9.7c-.3732-3.1088-3.98-5.9076-12.0007-5.9076-8.9536,0-11.0678,2.4256-11.0678,5.41,0,4.1039,7.2127,4.3527,14.8607,5.41C158.4853,36.8028,166.8175,37.7979,166.8175,47.4983Z" fill="#283a97"/>
            <path d="M206.9235,52.5342h-23.877l-3.7307,8.5188H168.6829l20.1463-43.5258h12.3116L221.2249,61.053H210.592Zm-3.3575-7.648L194.985,25.113l-8.5809,19.7732Z" fill="#283a97"/>
            <path d="M259.3412,43.83h10.3836c-2.0519,12.9327-13.0575,18.1561-24.3741,18.1561-12.56,0-24.7477-6.342-24.7477-22.6953S232.79,16.6565,245.3507,16.6565c11.3166,0,22.3222,5.2234,24.3741,18.157H259.3412c-1.3061-5.6588-4.9746-10.57-13.9905-10.57-10.4463,0-14.488,6.6529-14.488,15.0473,0,8.4565,4.0417,15.1094,14.488,15.1094C254.3666,54.4,258.0351,49.4876,259.3412,43.83Z" fill="#283a97"/>
            <path d="M322.5152,17.5272V61.053H312.5043V42.5859h-26.55V61.053H275.9429V17.5272h10.0109V34.8757h26.5505V17.5272Z" fill="#283a97"/>
            <path d="M370.02,61.053h-38.8V17.5272h38.1784v7.71H341.231v9.6383h26.4261v7.648H341.231v10.819H370.02Z" fill="#283a97"/>
            <path d="M411.9292,61.053l-9.2024-14.7364H386.8709V61.053H376.86V17.5272h26.8614c11.8141,0,17.9078,5.6588,17.9078,14.4263,0,6.84-3.2332,10.9434-8.9537,12.9327L423.06,61.053ZM386.8709,38.7308h16.4773c6.0937,0,8.0834-3.171,8.0834-6.7773,0-3.7307-1.8653-6.7161-8.0834-6.7161H386.8709Z" fill="#283a97"/>
            <path d="M142.2333,85.304c0,4.0639-3.3416,6.229-9.9981,6.229-6.4695,0-9.8646-2.4323-9.8646-6.229h4.4914c.1069,1.9513,2.3522,2.9408,5.7472,2.9408,3.5558,0,5.0525-.9351,5.0525-2.3521,0-1.9246-2.8869-1.7376-7.9929-2.5935s-6.71-2.4056-6.71-5.3464c0-3.0745,2.0854-5.9075,8.9556-5.9075,6.6027,0,9.169,2.5124,9.2759,5.8273h-4.17c-.1608-1.3368-1.7109-2.5391-5.16-2.5391-3.8492,0-4.7581,1.042-4.7581,2.3254,0,1.7643,3.1012,1.8712,6.3889,2.3254C138.6508,80.7066,142.2333,81.1341,142.2333,85.304Z" fill="#283a97"/>
            <path d="M181.1332,81.7753c0,7.1642-5.7472,9.7577-11.1475,9.7577s-11.1742-2.5935-11.1742-9.7577c0-7.1374,5.7744-9.73,11.1742-9.73S181.1332,74.6379,181.1332,81.7753Zm-4.3841,0c0-3.6622-2.1117-6.4686-6.7634-6.4686s-6.7634,2.8064-6.7634,6.4686c0,3.689,2.1121,6.4962,6.7634,6.4962S176.7491,85.4643,176.7491,81.7753Z" fill="#283a97"/>
            <path d="M215.222,91.1322H198.7811V72.4193h4.3311v15.398h12.11Z" fill="#283a97"/>
            <path d="M251.9029,72.4193V83.5664c0,6.55-4.2238,7.9666-9.3832,7.9666-5.1327,0-9.3832-1.417-9.3832-7.9666V72.4193h4.304v10.88c0,3.8225,1.8712,4.9723,5.0792,4.9723s5.0792-1.15,5.0792-4.9723v-10.88Z" fill="#283a97"/>
            <path d="M288.0231,75.7342h-7.0844v15.398h-4.304V75.7342h-7.084V72.4193h18.4724Z" fill="#283a97"/>
            <path d="M309.9745,72.4193V91.1322h-4.304V72.4193Z" fill="#283a97"/>
            <path d="M349.9422,81.7753c0,7.1642-5.7477,9.7577-11.1475,9.7577s-11.1742-2.5935-11.1742-9.7577c0-7.1374,5.7743-9.73,11.1742-9.73S349.9422,74.6379,349.9422,81.7753Zm-4.3841,0c0-3.6622-2.1121-6.4686-6.7634-6.4686s-6.7634,2.8064-6.7634,6.4686c0,3.689,2.1121,6.4962,6.7634,6.4962S345.5581,85.4643,345.5581,81.7753Z" fill="#283a97"/>
            <path d="M387.1049,72.4193V91.1322h-4.8387L371.894,77.7657V91.1322H367.59V72.4193h4.8121l10.3722,13.3932V72.4193Z" fill="#283a97"/>
            <path d="M424.3483,85.304c0,4.0639-3.3416,6.229-9.9982,6.229-6.469,0-9.8641-2.4323-9.8641-6.229h4.491c.1069,1.9513,2.3526,2.9408,5.7477,2.9408,3.5553,0,5.052-.9351,5.052-2.3521,0-1.9246-2.8869-1.7376-7.9929-2.5935s-6.71-2.4056-6.71-5.3464c0-3.0745,2.0855-5.9075,8.9557-5.9075,6.6031,0,9.169,2.5124,9.2763,5.8273h-4.17c-.16-1.3368-1.7109-2.5391-5.1594-2.5391-3.85,0-4.7586,1.042-4.7586,2.3254,0,1.7643,3.1011,1.8712,6.3893,2.3254C420.7662,80.7066,424.3483,81.1341,424.3483,85.304Z" fill="#283a97"/>
        </g>*/}
        <g id="logoMark">
            <polygon id="logoMark_PathItem_" data-name="logoMark &lt;PathItem&gt;" points="78.513 54.287 106.806 67.721 87.944 76.677 59.651 63.243 78.513 54.287" fill="#0076b6"/>
            <polygon id="logoMark_PathItem_2" data-name="logoMark &lt;PathItem&gt;" points="18.863 73.653 47.156 87.087 28.294 96.044 0.001 82.609 18.863 73.653" fill="#0076b6"/>
            <polygon id="logoMark_PathItem_3" data-name="logoMark &lt;PathItem&gt;" points="9.432 87.087 78.513 54.287 97.375 63.243 28.294 96.044 9.432 87.087" fill="#0076b6"/>
            <polygon id="logoMark_PathItem_4" data-name="logoMark &lt;PathItem&gt;" points="78.513 29.339 106.806 42.773 87.944 51.729 59.651 38.295 78.513 29.339" fill="#00aeef"/>
            <polygon id="logoMark_PathItem_5" data-name="logoMark &lt;PathItem&gt;" points="18.863 48.706 47.156 62.14 28.294 71.096 0.001 57.662 18.863 48.706" fill="#00aeef"/>
            <polygon id="logoMark_PathItem_6" data-name="logoMark &lt;PathItem&gt;" points="9.432 62.14 78.513 29.339 97.375 38.295 28.294 71.096 9.432 62.14" fill="#00aeef"/>
            <polygon id="logoMark_PathItem_7" data-name="logoMark &lt;PathItem&gt;" points="78.513 4.392 106.806 17.826 87.944 26.782 59.651 13.348 78.513 4.392" fill="#00d5ff"/>
            <polygon id="logoMark_PathItem_8" data-name="logoMark &lt;PathItem&gt;" points="18.863 23.759 47.156 37.193 28.294 46.149 0.001 32.715 18.863 23.759" fill="#00d5ff"/>
            <polygon id="logoMark_PathItem_9" data-name="logoMark &lt;PathItem&gt;" points="9.432 37.193 78.513 4.392 97.375 13.348 28.294 46.149 9.432 37.193" fill="#00d5ff"/>
            <polygon id="logoMark_PathItem_10" data-name="logoMark &lt;PathItem&gt;" points="31.547 0 50.406 8.962 18.868 23.766 0 14.805 31.547 0" fill="#00d5ff"/>
            <polygon id="logoMark_PathItem_11" data-name="logoMark &lt;PathItem&gt;" points="87.943 76.679 106.802 85.631 75.35 100.475 56.491 91.523 87.943 76.679" fill="#0076b6"/>
        </g>
      </g>
    </chakra.svg>
);
