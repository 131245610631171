import {useSession} from "../context/SessionContext";
import {useNavigate} from "react-router-dom";

const GOOGLE_AUTH_URL = "https://accounts.google.com/o/oauth2/v2/auth";
const CLIENT_ID = "685176712010-d8p0r3nqe4a8hq3hgv52nqtmg5kt4dl8.apps.googleusercontent.com";
const REDIRECT_URI = "https://auth-showcase.sacher-solutions.com/auth/google-callback"; // Adjust based on your app
const RESPONSE_TYPE = "code";
const SCOPE = "email"; // Space-separated list of scopes

export function useGoogleOAuthLogin(state, onComplete) {
    const {sessionId} = useSession();

    const startGoogleLogin = () => {
        const authUrl = `${GOOGLE_AUTH_URL}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}&state=${encodeURIComponent(state)}`;

        const width = 600, height = 600;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;
        const options = `width=${width},height=${height},top=${top},left=${left}`;

        const popup = window.open(authUrl, 'GoogleLoginPopup', options);
        popup.onload = function() {
            const message = { sessionId: sessionId };
            popup.postMessage(message, popup.location.origin);
        };

        window.addEventListener("message", (event) => {
            if (event.origin !== "https://auth-showcase.sacher-solutions.com") return;

            const { code, googleCallbackState } = event.data;

            if (code) {
                fetch("https://auth-showcase-be.sacher-solutions.com/auth/google", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        auth_code: code,
                        session_id: sessionId,
                        state: googleCallbackState
                    }),
                })
                    .then(response => {
                        if(response.ok){
                            onComplete()
                        }
                    })
                    .catch(error => {
                        console.error("Error exchanging the authorization code:", error);
                    });
            }
        });
    };

    return { startGoogleLogin }
}