import {Container, Heading, Stack} from "@chakra-ui/react";
import React from "react";

function LoginSuccessPage() {
    return (
        <Container>
            <Stack
                spacing={{
                    base: '2',
                    md: '3',
                }}
                textAlign="center"
            >
                <Heading
                    size={{
                        base: 'xs',
                        md: 'sm',
                    }}
                >
                    Login Complete! You can go back to the app.
                </Heading>
            </Stack>
        </Container>
    );
}

export default LoginSuccessPage;