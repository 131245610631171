import React, { useEffect } from 'react';

const AppleCallbackPage = () => {
    useEffect(() => {
        const handleAppleResponse = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const state = urlParams.get('state');

            if (code) {
                try {
                    console.log('code: ' + code)
                    console.log('state: ' + state)
                    return;
                    const response = await fetch('/api/auth/apple/callback', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ code, state }),
                    });

                    if (response.ok) {
                        const data = await response.json();
                        // Handle the response data, such as saving tokens and signing in the user
                        console.log('Successfully authenticated with Apple', data);
                        // Redirect to another page or change the state as needed
                    } else {
                        throw new Error('Failed to exchange the code for tokens');
                    }
                } catch (error) {
                    console.error('Error during Apple sign-in:', error);
                }
            }
        };

        handleAppleResponse();
    }, []);

    return (
        <div>
            <h1>Processing Apple Sign In...</h1>
        </div>
    );
};

export default AppleCallbackPage;
