import { useNavigate } from 'react-router-dom';
import {useEffect} from "react";

const UnityPage = () => {
    let navigate = useNavigate();

    const redirectToUnity = () => {
        window.location.href = '/unity/index.html'; // Adjust the path as necessary
    };

    useEffect(redirectToUnity)
};

export default UnityPage;
