import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'; // Import ChakraProvider
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import AppleCallbackPage from "./pages/AppleCallbackPage";
import { SessionProvider } from './context/SessionContext';
import LoginSuccessPage from "./pages/LoginSuccessPage";
import './assets/styles/App.css';
import UnityPage from "./pages/UnityPage";
import {GoogleCallbackPage} from "./pages/GoogleCallbackPage";

function App() {
    return (
        <div className="App">
            <ChakraProvider>
                <SessionProvider>
                    <Router>
                        <Routes>
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/auth/apple-callback" element={<AppleCallbackPage />} />
                            <Route path="/auth/google-callback" element={<GoogleCallbackPage />} />
                            <Route path="/auth/success" element={<LoginSuccessPage />} />
                            <Route path="/" element={<UnityPage />} />
                        </Routes>
                    </Router>
                </SessionProvider>
            </ChakraProvider>
        </div>
    );
}

export default App;
