import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Box, Center,
    Container,
    Heading,
    Stack,
    Spinner,

} from '@chakra-ui/react';
import {OAuthButtonGroup} from '../components/OAuthButtonGroup';
import {Logo} from '../components/Logo';
import { useSession } from '../context/SessionContext';

function LoginPage() {
    const location = useLocation();
    const { setSessionId } = useSession();
    const [isValidSession, setIsValidSession] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [state, setState] = useState("");

    useEffect(() => {

        const confirmSession = async (sessionId) => {
            setIsLoading(true);
            try {
                const response = await fetch('https://auth-showcase-be.sacher-solutions.com/auth/confirm-session', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ session_id: sessionId }),
                });

                if (response.ok) {
                    setSessionId(sessionId);
                    setIsValidSession(true);
                    const data = await response.json()
                    console.log("fetched state successfully: " + data.state)
                    setState(data.state)
                } else {
                    throw new Error('Session confirmation failed');
                }
            } catch (error) {
                console.error('Error confirming session:', error);
                setIsValidSession(false);
            } finally {
                setIsLoading(false);
            }
        };

        const searchParams = new URLSearchParams(location.search);
        const sessionId = searchParams.get('id');

        if (sessionId && sessionId.length === 128) {
            confirmSession(sessionId);
        } else {
            setIsValidSession(false);
            setIsLoading(false);
        }
    }, [location, setSessionId]);

    var loadingPage = (
        <Center height="100vh"> {/* Center the spinner vertically and horizontally */}
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
            />
        </Center>
    );

    var errorPage = (
        <Heading
            size={{
                base: 'xs',
                md: 'sm',
            }}
        >
            Session is invalid. Every login link can only be used once. Start the login from the app.
        </Heading>
    );

    var loginPage = (
        <Container
            maxW="lg"
            py={{
                base: '12',
                md: '24',
            }}
            px={{
                base: '0',
                sm: '8',
            }}
        >
            <Stack spacing="8">
                <Stack spacing="6">
                    <Center>
                        <Logo />
                    </Center>
                    <Stack
                        spacing={{
                            base: '2',
                            md: '3',
                        }}
                        textAlign="center"
                    >
                        <Heading
                            size={{
                                base: 'xs',
                                md: 'sm',
                            }}
                        >
                            Log in to your account
                        </Heading>
                    </Stack>
                </Stack>
                <Box
                    py={{
                        base: '0',
                        sm: '8',
                    }}
                    px={{
                        base: '4',
                        sm: '10',
                    }}
                    bg="white"
                    boxShadow={{
                        base: 'none',
                        sm: 'md',
                    }}
                    borderRadius={{
                        base: 'none',
                        sm: 'xl',
                    }}
                >
                    <Stack spacing="6">
                        <OAuthButtonGroup state={state}/>
                    </Stack>
                </Box>
            </Stack>
        </Container>
    );

    if (isLoading) return loadingPage;

    if(isValidSession)
        return loginPage;
    else
        return errorPage;
}

export default LoginPage;
