import { useEffect } from "react";

export function GoogleCallbackPage() {
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get("code");
        const state = queryParams.get("state");

        if (window.opener) {
            window.opener.postMessage({
                code: code,
                googleCallbackState: state
            }, "https://auth-showcase.sacher-solutions.com");
            window.close();
        }
    }, []);

    return <div>Authenticating...</div>;
}
