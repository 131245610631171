import { useEffect } from 'react';
import {useSession} from "../context/SessionContext";

export function useAppleLogin(state, onComplete) {
    const {sessionId} = useSession();

    const initializeAppleLogin = async () => {
        window.AppleID.auth.init({
            clientId: 'com.SacherSolutions.AppleLoginTestService',
            scope: 'name email',
            redirectURI: 'https://auth-showcase.sacher-solutions.com/auth/apple-callback',
            state: state,
            usePopup: true,
        });
    };

    const startAppleLogin = () => {
        window.AppleID.auth.signIn();
    };

    const handleAppleLogin = async (appleResponse) => {
        console.log("Apple Login Success: " + JSON.stringify(appleResponse, null, 2));

        try {
            const backendUrl = 'https://auth-showcase-be.sacher-solutions.com/auth/apple';

            const bodyData = {
                code: appleResponse.authorization.code,
                state: appleResponse.authorization.state,
                session_id: sessionId
            };

            const response = await fetch(backendUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyData)
            });

            const data = await response.json();

            if (response.ok) {
                console.log("Backend login successful: ", data.message);
                onComplete();
            } else {
                console.error("Backend login failed: ", data.message);
            }
        } catch (error) {
            console.error("Network error during backend login: ", error);
        }
    };


    useEffect(() => {
        const onSuccess = (event) => handleAppleLogin(event.detail);
        const onFailure = (error) => console.error("Apple Login Error: " + error);

        document.addEventListener('AppleIDSignInOnSuccess', onSuccess);
        document.addEventListener('AppleIDSignInOnFailure', onFailure);

        return () => {
            document.removeEventListener('AppleIDSignInOnSuccess', onSuccess);
            document.removeEventListener('AppleIDSignInOnFailure', onFailure);
        };
    }, [sessionId, state, onComplete]);

    return { initializeAppleLogin, startAppleLogin }
}