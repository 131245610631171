import {Button, VStack} from '@chakra-ui/react'
import { AppleIcon, GoogleIcon } from './ProviderIcons'
import {useAppleLogin} from "../hooks/AppleLoginHook";
import {useCallback, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useGoogleOAuthLogin} from "../hooks/GoogleLoginHook";

const providers = [
  {
    name: 'Google',
    icon: <GoogleIcon />,
    loginPage: 'auth/google',
  },
  {
    name: 'Apple',
    icon: <AppleIcon />,
    loginPage: 'auth/apple',
  },
]

export const OAuthButtonGroup = ({state}) => {
  let navigate = useNavigate();

  const onCompleteApple = useCallback(() => {
    navigate('/auth/success');
  }, [navigate]);

  const { initializeAppleLogin, startAppleLogin } = useAppleLogin(state, onCompleteApple);

  useEffect(() => {
    initializeAppleLogin();
  }, [initializeAppleLogin]);

  const { startGoogleLogin } = useGoogleOAuthLogin(state, onCompleteApple);

  const handleProviderClick = (provider) => {
    switch (provider.name) {
      case 'Apple':
        console.log('Starting Apple Login');
        startAppleLogin();
        break;
      case 'Google':
        console.log('Starting Google Login');
        startGoogleLogin();
        break;
      default:
        console.warn('Unknown provider');
    }
  };

  return (
      <VStack spacing="4">
        {providers.map((provider) => (
            <Button
                key={provider.name}
                w="full"
                variant="outline"
                leftIcon={provider.icon}
                colorScheme="whiteAlpha"
                color="black"
                _hover={{
                  bg: 'whiteAlpha.300',
                }}
                onClick={() => handleProviderClick(provider)}
            >
              Continue with {provider.name}
            </Button>
        ))}
      </VStack>
  );
}
